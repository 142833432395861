import axios from "axios";

const api = axios.create({
  baseURL: "https://api.levelkazino.com/",
});

export const fetchDataByType = async (type) => {
  try {
    const response = await api.get(`api/Person/get-by-type/${type}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
